@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Muli:wght@400;700&display=swap");

// @font-face {
//   font-family: "Muli";
//   font-weight: 100 900;
//   font-display: swap;
//   font-style: normal;
//   font-named-instance: "Regular";
//   src: url("/fonts/Elliana Samantha.woff2") format("woff2");
// }
// @font-face {
//   font-family: "Muli";
//   font-weight: normal;
//   font-display: swap;
//   font-style: normal;
//   font-named-instance: "Regular";
//   src: url("/fonts/GothamBook.woff2") format("woff2");
// }
// @font-face {
//   font-family: "Muli";
//   font-weight: bold;
//   font-display: swap;
//   font-style: normal;
//   font-named-instance: "Regular";
//   src: url("/fonts/GothamBold.woff2") format("woff2");
// }
// @font-face {
//   font-family: "Muli";
//   font-weight: normal;
//   font-display: swap;
//   font-style: italic;
//   font-named-instance: "Regular";
//   src: url("/fonts/Gotham-BookItalic.woff2") format("woff2");
// }
// @font-face {
//   font-family: "Muli";
//   font-weight: bold;
//   font-display: swap;
//   font-style: italic;
//   font-named-instance: "Regular";
//   src: url("/fonts/GothamBoldItalic.woff2") format("woff2");
// }

body {
  font-family: "Muli", sans-serif;
}
body a,
body a:hover {
  text-decoration: none;
}

.text-8xl {
  font-size: 60;
}

h1 {
  // font-family: "Muli", sans-serif;
  @apply text-4xl uppercase md:text-5xl lg:text-6xl font-bold tracking-wide;
}
h1.heading {
  @apply text-5xl uppercase md:text-7xl font-bold lg:text-[100px] mb-4;
}
h2 {
  font-family: "Muli", sans-serif;

  @apply text-3xl md:text-4xl lg:text-5xl font-extrabold mb-4 tracking-wide;
}
h2.heading {
  @apply text-3xl uppercase  md:text-3xl lg:text-5xl mb-4;
}
h3 {
  // font-family: "Muli", sans-serif;
  @apply text-2xl uppercase md:text-3xl lg:text-4xl font-bold mb-4 tracking-wide;
}
h3.heading {
  @apply text-xl uppercase md:text-2xl lg:text-3xl font-semibold mb-4;
}
h4 {
  font-family: "Muli", sans-serif;
  @apply text-lg uppercase  lg:text-2xl font-medium mb-4 tracking-wide;
}
h5 {
  font-family: "Muli", sans-serif;
  @apply text-lg uppercase lg:text-xl font-medium tracking-wide;
}
.title-heading {
  @apply bg-cover  bg-no-repeat relative overflow-hidden;
}
.title-heading:before {
  @apply bg-fixed absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.counter-number {
  @apply text-3xl md:text-4xl lg:text-3xl font-extrabold;
}
.btn-red {
  @apply bg-blue-700 hover:bg-red-600 text-white font-semibold py-2 px-4;
}

.accordion-features {
  button {
    @apply p-4 bg-white;
  }
  h2 {
    @apply text-xl font-normal text-black;
  }
  div,
  p {
    @apply text-base;
  }
}

.accordion-benefits {
  button {
    @apply py-2 pl-0 ml-0 bg-transparent;
  }
  h2 {
    @apply text-2xl font-semibold text-black;
  }
}

.justify-col {
  @apply text-justify;
}

.btn-white-sm {
  @apply bg-white hover:bg-brown-400 text-brown-800 hover:text-white border-current text-base font-bold py-3 md:px-6 my-2;
}
.btn-black-med {
  @apply bg-black hover:bg-white text-white hover:text-black border-current rounded-lg text-base font-bold py-3 md:px-6 my-2;
}
.btn-blueline-med {
  @apply text-indigo-200 hover:text-white border-indigo-200 border-2 hover:border-white text-lg font-bold py-3 md:px-6 my-2;
}
.wht-outline {
  @apply bg-white border border-gray-600 text-red-600 font-semibold rounded-full uppercase px-6 py-3;
}

.pricebox {
  @apply bg-black hover:bg-indigo-500 p-5 lg:p-10;
}
.articlebox {
  @apply bg-black hover:bg-indigo-500;
  .blog-details {
    @apply text-brown-200 p-10;
  }
}

.service-box {
  @apply bg-white p-1 lg:p-2  text-black mb-3 md:mb-0 shadow-2xl;
  h5 {
    @apply font-bold my-4;
  }
  .wht-outline {
    @apply bg-white border border-gray-600 text-red-600 font-semibold rounded-full uppercase px-6 py-3;
  }
}
.service-box1 {
  @apply bg-gray-500 hover:bg-gray-200 p-6 lg:p-8 rounded-2xl text-white hover:text-black mb-3 md:mb-0;
  h5 {
    @apply font-bold my-4;
  }
  .wht-outline {
    @apply bg-white border border-gray-600 text-red-600 font-semibold rounded-full uppercase px-6 py-3;
  }
}

ul.bottomnenu {
  @apply m-0 p-0;
}
ul.bottomnenu li {
  @apply m-0 p-0;
}
ul.bottomnenu li a {
  @apply font-bold text-brown-200 hover:text-indigo-500 py-3 uppercase;
}
nav .active {
  @apply text-blue-500 font-bold;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.header-dropdown {
  @apply relative;
}
.header-dropdown-menu {
  @apply origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none;
}
.header-dropdown-item {
  @apply block px-4 py-2 text-gray-700 hover:bg-gray-100;
}
